<template>
  <v-container class="mt-5 mb-15 px-10" fluid>
    <PageHeader>
      <template #header>
        <img
          src="@/assets/plugins/rep-management.svg"
          alt="rep management logo"
          height="32"
        >
        {{ installed ? 'Update ' : 'Install' }} Rep management
      </template>
      <template #subheader>
        Please configure the following in order to install the Rep management plugin.
      </template>
    </PageHeader>

    <div class="section-header">
      Configuration
    </div>
    <div class="mt-8">
      <validation-observer ref="inputRef">
        <v-row class="form-container">
          <v-col cols="6" sm="6" class="py-0">
            <v-checkbox
              v-model="sendEmailToRep"
              :false-value="false"
              class="mt-4"
              label="Send Emails to Rep when Contacts are assigned"
            />
          </v-col>
          <v-col cols="6" sm="6" class="py-0">
            <v-btn
              v-if="installed"
              class="custom-button custom-button--blue px-13"
              height="34px"
              depressed
              @click="updateContacts()"
            >
              Assign Reps to Unassigned Contacts
            </v-btn>
          </v-col>
          <v-col cols="6" sm="6" class="py-0">
            <CustomTextInput
              v-if="sendEmailToRep"
              v-model="subject"
              header="Subject"
              required
            />
          </v-col>
          <v-col cols="6" sm="6" class="py-0">
            <CustomTextInput
              v-if="sendEmailToRep"
              v-model="previewText"
              :maxlength="150"
              header="Preview Text"
            />
          </v-col>
          <v-col cols="6" sm="6" class="py-0">
            <CustomTextInput
              v-if="sendEmailToRep"
              v-model="repName"
              header="Default Rep Name"
            />
          </v-col>
          <v-col cols="6" sm="6" class="py-0">
            <CustomTextInput
              v-if="sendEmailToRep"
              v-model="repEmail"
              header="Default Rep Email"
            />
          </v-col>
          <v-col cols="6" sm="6" class="py-0">
            <CustomDropdown
              v-model="segments"
              autocomplete
              header-class="field__header"
              header="Select segments"
              short
              multiple
              required
              item-text="text"
              subheader=""
              hint="The contact must be part of one of these segments to be assigned a rep"
              :items="segmentsToSelect"
            />
          </v-col>
          <v-col cols="6" sm="6" class="py-0">
            <CustomDropdown
              v-if="sendEmailToRep"
              v-model="creativeId"
              autocomplete
              header-class="field__header"
              header="Select creative"
              short
              required
              item-text="text"
              subheader=""
              hint="This email creative will be what gets emailed to the rep when a contact gets assigned to them"
              :items="creativeToSelect"
            />
          </v-col>
        </v-row>
      </validation-observer>
    </div>

    <validation-observer ref="autoExportConfigs">
      <AutomatedExportConfigurations
        :automated-export-configurations="exportConfigurations"
        :show-export-delta-files-checkbox="false"
        :show-create-empty-files-checkbox="false"
        :default-export-config="defaultExportConfig"
        :export-type-options="[{id: exportType, text: 'Rep Management Export'}]"
        @add-configuration="type => addExportConfiguration({type}, 0)"
        @clone-configuration="cloneExportConfiguration"
        @delete-configuration="deleteExportConfiguration"
        @update-configuration="updateExportValue"
      />
    </validation-observer>

    <hr class="mt-7">

    <validation-observer ref="autoImportConfigs">
      <AutomatedImportConfigurations
        :id="id"
        :plugin-data="plugin"
        :import-configurations="importConfigurations"
        :import-type="importType"
        :file-extensions="fileExtensionOptions"
        @add-configuration="addImportConfiguration"
        @delete-configuration="deleteImportConfiguration"
        @update-configuration="updateImportValue"
      />
    </validation-observer>

    <v-row class="mt-12">
      <v-col cols="12" class="d-flex justify-end">
        <v-btn
          v-if="installed"
          class="custom-button custom-button--red-text px-8 mr-4"
          height="34px"
          depressed
          @click="deactivateDialog = true;"
        >
          Deactivate
        </v-btn>
        <v-btn
          class="custom-button custom-button--blue px-13"
          height="34px"
          depressed
          :block="$vuetify.breakpoint.xsOnly"
          @click="installPlugin(null, null, false)"
        >
          {{ installed ? "Configure" : "Install" }}
        </v-btn>
      </v-col>
    </v-row>

    <DeactivateConfirmDialog
      v-if="deactivateDialog"
      :id="id"
      :plugin="plugin"
      @dismiss="deactivateDialog = false;"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/sharedComponents/PageHeader";
import AutomatedImportConfigurations from "@/views/Plugins/components/AutomatedImportConfigurations";
import AutomatedExportConfigurations from "@/views/Plugins/components/AutomatedExportConfigurations.vue";
import { v4 as uuidv4 } from "uuid";
import DeactivateConfirmDialog from "./components/DeactivateConfirmDialog";
import CustomTextInput from "@/sharedComponents/CustomTextInput";
import CustomDropdown from "@/sharedComponents/CustomDropdown";
import importExportMixin from "@/utils/import-export-mixin";

export default {
  name: "AddPluginRepManagement",
  metaInfo: {
    title: 'Install Rep Management'
  },
  components: {
    AutomatedExportConfigurations,
    AutomatedImportConfigurations,
    PageHeader,
    DeactivateConfirmDialog,
    CustomTextInput,
    CustomDropdown,
  },
  mixins: [importExportMixin],
  props: {
    id: {
      default: () => null,
      type: [Number, String],
    },
    installed: {
      default: false,
      type: Boolean,
    },
  },
  data: () => ({
    deactivateDialog: false,
    plugin: null,
    sendEmailToRep: false,
    subject: null,
    previewText: null,
    repName: null,
    repEmail: null,
    availableSegments: [],
    availableCreatives: [],
    availableReps: [],
    segments: [],
    creativeId: null,
    defaultRepId: null,
    fileExtensionOptions: [
      { id: 'csv', text: 'Comma-Separated Values (.csv)' },
    ],
    importType: 'rep_management',
    exportType: 'rep_management',
    defaultExportConfig: {
      exportType: 'rep_management',
      // The vendor specifically requested delta files and empty files
      exportDeltaFiles: true,
      canCreateEmptyFile: true,
    },
  }),
  computed: {
    segmentsToSelect: {
      get() {
        return this.availableSegments.map(i => ({
          text: `${i.id} - ${i.name}`,
          value: i.id,
        }));
      },
    },
    creativeToSelect: {
      get() {
        return this.availableCreatives.map(i => ({
          text: `${i.id} - ${i.name}`,
          value: i.id,
        }));
      },
    },
  },
  async created() {
    if (this.installed) {
      await this.loadAccountPlugin();
    }
    await this.loadSegments();
    await this.loadCreatives();
  },
  methods: {
    async loadAccountPlugin() {
      const resp = await this.$rest.plugins.get_resource(this.id, {
        includeCredentials: true,
      });
      if (!resp.data) {
        return;
      }

      this.plugin = {...resp.data};
      this.sendEmailToRep = this.plugin?.pluginConfiguration?.sendEmailToRep;
      this.subject = this.plugin?.pluginConfiguration?.subject;
      this.previewText = this.plugin?.pluginConfiguration?.previewText;
      this.repName = this.plugin?.pluginConfiguration?.repName;
      this.repEmail = this.plugin?.pluginConfiguration?.repEmail;
      this.segments = this.plugin?.pluginConfiguration?.segments;
      this.creativeId = this.plugin?.pluginConfiguration?.creativeId;

      let credentials = resp.data.pluginConfiguration?.credentials;
      if (!credentials || Array.isArray(credentials)) {
        credentials = {};
      }

      const configMapFunc = config => {
        const configCredentials = credentials[config.key] ?? null;

        if(config?.frequency?.time) {
          const [hour, minute] = config.frequency.time.split(":");
          if (+hour >= 12) {
            config.frequency.ampm = "PM"
            config.frequency.hour = +hour > 12 ? +hour - 12 : 12;
          }
          else {
            config.frequency.ampm = "AM"
            config.frequency.hour = (+hour === 0) ? 12 : hour;
          }
          config.frequency.minute = minute;

          // Remove this config in case the frequency changes
          delete config.frequency.nextRunDate;
        }

        return {
          ...config,
          credentials: configCredentials,
        };
      };

      if (resp.data.pluginConfiguration?.exportConfigurations) {
        const exportConfigurations = resp.data.pluginConfiguration?.exportConfigurations.map(configMapFunc);
        exportConfigurations.forEach(config => {
          this.addExportConfiguration(config);
        });
      }

      if (resp.data.pluginConfiguration?.importConfigurations) {
        const importConfigurations = resp.data.pluginConfiguration?.importConfigurations.map(configMapFunc);
        importConfigurations.forEach(config => {
          this.addImportConfiguration(config);
        });
      }
    },
    addExportConfiguration(existingConfig) {
      this.exportConfigurations.push(this.generateExportConfiguration({
        ...existingConfig,
        ...this.defaultExportConfig,
      }));
    },
    cloneExportConfiguration(index) {
      const existingConfig = this.exportConfigurations[index] ?? null;
      this.addExportConfiguration({
        ...existingConfig,
        name: 'Clone of ' + existingConfig?.name || '',
        key: null,
        history: null,
        lastExportDate: null,
      });
    },
    addImportConfiguration(existingConfig) {
      this.importConfigurations.push({
        connectionType: existingConfig?.connectionType || null,
        directory: existingConfig?.directory || '/',
        archiveDirectory: existingConfig?.archiveDirectory || '',
        fileExtension: existingConfig?.fileExtension || this.fileExtensionOptions[0]?.id,
        credentials: {
          host: existingConfig?.credentials?.host || null,
          username: existingConfig?.credentials?.username || null,
          password: existingConfig?.credentials?.password || null,
          port: existingConfig?.credentials?.port || null,
          privateKey: existingConfig?.credentials?.privateKey || null,
          aws_region: existingConfig?.credentials?.aws_region || null,
          aws_access_id: existingConfig?.credentials?.aws_access_id || null,
          aws_secret_key: existingConfig?.credentials?.aws_secret_key || null,
          aws_bucket: existingConfig?.credentials?.aws_bucket || null,
        },
        frequency: existingConfig?.frequency || {
          type: null,
          ampm: (new Date()).getHours() >= 12 ? 'PM' : 'AM',
          hour: (new Date()).getHours(),
          minute: '00',
          timezone: 'America/New_York',
          date: [],
        },
        importType: this.importType,
        importOptions: existingConfig?.importOptions ? {...existingConfig?.importOptions} : { choice: null },
        name: existingConfig?.name || '',
        notificationRecipients: existingConfig?.notificationRecipients || '',
        key: existingConfig?.key || uuidv4(),
        isShowing: false,
        history: existingConfig?.history ? [...existingConfig?.history] : [],
        isExisting: !!existingConfig?.key,
      });
    },
    getFormattedPluginConfiguration(expectedExportConfig, expectedImportConfig) {
      const pluginConfiguration = {
        exportConfigurations: [],
        importConfigurations: [],
        credentials: {},
        sendEmailToRep: this.sendEmailToRep,
        subject: this.subject,
        previewText: this.previewText,
        repName: this.repName,
        repEmail: this.repEmail,
        segments: this.segments,
        creativeId: this.creativeId,
      };

      for (const config of (expectedExportConfig || this.exportConfigurations)) {
        const result = this.formatConfig(config);

        pluginConfiguration.exportConfigurations.push(result.configuration);
        pluginConfiguration.credentials[result.configuration.key] = result.credentials;
      }
      for (const config of (expectedImportConfig || this.importConfigurations)) {
        const result = this.formatConfig(config);

        pluginConfiguration.importConfigurations.push(result.configuration);
        pluginConfiguration.credentials[result.configuration.key] = result.credentials;
      }

      return pluginConfiguration;
    },
    async validate() {
      return await this.$refs.inputRef.validate();
    },
    async installPlugin(expectedExportConfig, expectedImportConfig, returnResponse) {
      if (!(await this.validate())) {
        this.$notifier.error("Please check the form again.");
        return;
      }
      const pluginConfiguration = this.getFormattedPluginConfiguration(expectedExportConfig, expectedImportConfig);

      let response = null;
      if (this.installed) {
        response = await this.$rest.plugins
          .put_resource(this.id, {
            isActive: true,
            pluginConfiguration,
          })
          .then((resp) => {
            const selected_account = this.$store.getters["user/account"];
            this.$store.commit("user/update_selected_account", {
              ...selected_account,
              activePlugins: [...selected_account.activePlugins, resp.data],
            });

            return resp;
          })
          .catch(error => {
            let error_message = null;
            let errors_list = "";
            if (error.response?.data?.errors?.length) {
              error.response.data.errors.forEach(item => {
                errors_list += (item.error + "\r \n");
              });
              error_message = errors_list;
            } else if (error.response?.data?.message) {
              error_message = error.response?.data?.message;
            }

            this.$store.commit('snackbar/showMessage', {
              content: error_message || 'Failed when trying to configure plugin',
              color: 'error',
            });
          });
      } else {
        response = await this.$rest.plugins
          .post_resource({
            plugin: +this.id,
            isActive: true,
            pluginConfiguration,
          })
          .then((resp) => {
            const selected_account = this.$store.getters["user/account"];
            this.$store.commit("user/update_selected_account", {
              ...selected_account,
              activePlugins: [...selected_account.activePlugins, resp.data],
            });

            return resp;
          })
          .catch((error) => {
            let error_message = null;
            let errors_list = "";
            if (error.response?.data?.errors?.length) {
              error.response.data.errors.forEach(item => {
                errors_list += (item.error + "\r \n");
              });
              error_message = errors_list;
            } else if (error.response?.data?.message) {
              error_message = error.response?.data?.message;
            }

            this.$store.commit('snackbar/showMessage', {
              content: error_message || 'Failed when trying to install plugin',
              color: 'error',
            });
          });
      }

      if (returnResponse) {
        return response;
      }
      if (!response?.data) {
        return;
      }

      this.$store.commit('snackbar/showMessage', {
        content: this.installed
          ? 'Plugin configured successfully'
          : 'Plugin installed successfully',
        color: 'success',
      });

      await this.$router.push({ name: 'Plugins' });
    },
    async loadSegments() {
      this.availableSegments = (
          await this.$rest.segment.get_collection({
            sort: ['name:asc'],
            isArchived: false,
            ignorePagination: true,
          })
      ).data.items;
    },
    async loadCreatives() {
      this.availableCreatives = (
          await this.$rest.creative.email.get_collection({
            sort: ['name:asc'],
            ignorePagination: true,
          })
      ).data.items;
    },
    async updateContacts() {
      await this.$rest.plugins.repManagement.updateContacts()
      this.$store.commit('snackbar/showMessage', {
        content: 'Your request will be processed. Please allow some time for changes to reach all contacts.',
        color: 'success',
      });
    },
  },
};
</script>
<style lang="scss">
.custom-dropdown {
  .field__header, .v-input  {
    font-size: 13px !important;
    font-weight: normal !important;
  }

  .field__header {
    color: #66788e !important;
  }
}
</style>
<style scoped>
.section-header {
  margin-top: 30px;
  font-size: 1.25rem;
  font-weight: bold;
}
</style>
